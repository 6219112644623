<template>
  <div class="page config">
    <div class="page__content">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="店铺基本信息">
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">店铺名称:</a-col>
            <a-col span="8"> <a-input v-model="info.name"></a-input></a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">店铺状态:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <a-radio-group name="radioGroup" v-model="info.is_active">
                <a-radio :value="true"> 已营业 </a-radio>
                <a-radio :value="false"> 未营业 </a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">创建时间:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>{{ info.create_time || "" }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">有效期至:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>{{ info.expire_date || "" }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">APPID:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>{{ info.app_id || "" }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">背景图片开关:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>
                <a-switch
                  checked-children="开"
                  un-checked-children="关"
                  default-checked
                  v-model="info.show_background"
                />
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">背景图片:</a-col>
            <a-col span="8">
              <a-upload
                name="image"
                action="/api/shop_backgrounds/"
                :withCredentials="true"
                list-type="picture-card"
                :headers="headers"
                @change="uploadChange"
                :multiple="false"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
              >
                <div v-if="info.background_url" class="image__box">
                  <img :src="info.background_url" style="display: inline-block; width: 375px; height: 100px" alt="" />
                  <div class="image__box_del" @click.stop="stopClick">
                    <a-icon type="delete" @click.stop="delImage()" />
                  </div>
                </div>
                <div v-else><a-icon type="plus" />上传图片</div>
              </a-upload>
              <span style="color: red">建议上传图片尺寸375px*100px</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">小程序码:</a-col>
            <a-col span="8">
              <img :src="info.qr_code_url" style="display: inline-block; width: 120px; height: 120px" alt="" />
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">积分兑换开关:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>
                <a-switch checked-children="开" un-checked-children="关" default-checked v-model="info.open_integral" />
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">系统说明:</a-col>
            <a-col span="12" style="min-height: 32px; line-height: 32px">
              <div>
                积分兑换：100积分兑换1人民币；发货后十天自动确认收货；确认收货后一天订单自动完成；未付款订单0.5小时内未付款自动取消订单
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">退货地址:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>
                <a-input v-model="info.receiving_address" placeholder="退货地址"></a-input>
              </div>
            </a-col>
          </a-row>

          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">自动分包:</a-col>
            <a-col span="8" style="height: 32px; line-height: 32px">
              <div>
                <a-switch
                  checked-children="开"
                  un-checked-children="关"
                  default-checked
                  v-model="info.auto_subpackage"
                />
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">分包重量:</a-col>
            <a-col span="8"> <a-input type="number" v-model.number="info.subpackage_weight"></a-input></a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">发货人名称:</a-col>
            <a-col span="8"> <a-input type="text" v-model="info.sender_name"></a-input></a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">发货人手机:</a-col>
            <a-col span="8"> <a-input type="text" v-model="info.sender_mobile"></a-input></a-col>
          </a-row>
          <a-row gutter="12">
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">省市区:</a-col>
            <a-col span="6"> <a-input type="text" v-model="info.sender_province"></a-input></a-col>
            <a-col span="6"> <a-input type="text" v-model="info.sender_city"></a-input></a-col>
            <a-col span="6"> <a-input type="text" v-model="info.sender_county"></a-input></a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">发货人地址:</a-col>
            <a-col span="8"> <a-input type="text" v-model="info.sender_address"></a-input></a-col>
          </a-row>
          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">发货人备注:</a-col>
            <a-col span="8"> <a-input type="text" v-model="info.sender_remark"></a-input></a-col>
          </a-row>

          <a-row>
            <a-col span="3" style="text-align: center; height: 32px; line-height: 32px">物流公司:</a-col>
            <a-col span="8">
              <a-select style="width: 100%" v-model="info.logistics_company">
                <a-select-option :value="item.id" v-for="item in logisticList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="config__btns">
      <a-button type="primary" @click="saveInfo">保存</a-button>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "ShopConfig",
  data() {
    return {
      info: {},
      imgUrl: "",
      logisticList: [],
    };
  },
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
  },
  created() {
    this.getInfo();
    this.getLogisticsCompany();
  },
  methods: {
    getLogisticsCompany() {
      this.$axios.get(`/logistics_companies/`).then((res) => {
        console.log(res);
        this.logisticList = res;
      });
    },
    uploadChange({ file }) {
      console.log(file);
      if (file.status === "done") {
        // this.getImg();
        this.info.background_url = file.response.image;
        this.info.background = file.response.id;
      }
    },
    getInfo() {
      this.$axios.get("/shop_configs/").then((res) => {
        this.info = res;
      });
    },
    saveInfo() {
      console.log(this.info, "info");
      this.$axios.put("/shop_configs/", { ...this.info }).then((res) => {
        console.log(res);
        this.$message.success("操作成功");
        this.getInfo();
      });
    },
    stopClick() {},
    delImage() {
      this.info.background_url = "";
      this.info.background = "";
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 2048;

      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(2048KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.config {
  position: relative;
  padding-bottom: 60px;
  &__btns {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 60px;
    border-top: 1px solid #dddddd;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}
.ant-row {
  margin-bottom: 4px;
}
.image__box {
  position: relative;

  .image__box_del {
    position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translateX(-50%) translateY(-50%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    font-size: 20px;
    color: red;
    vertical-align: middle;
    line-height: 100px;
  }
  &:hover {
    .image__box_del {
      display: block;
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
